/**
 * Style settings for the text throughout the game
 * @type {string}
 */

const PURPLE = '#6C2D86';
const RED = '#DE432F';
const RED2 = '#C31301';
const BLUE = '#00365A';
const BLUE2 = '#0B5384';
const WHITE = '#FFFFFF';
const PINK ='#CC2569';
const YELLOW = '#FFEC8C';
const GREEN = '#008184';
const FONT = 'Open Sans';
const FONT2 = 'Open Sans Condensed';


export const STYLES = {
    landing: {
        play_button: {
            fill: RED,
            font: FONT2,
            fontSize: '36px',
            fontWeight: 700,
            align: 'center'
        }
    },
    leaderboard: {
        header: {
            fill: YELLOW,
            font: FONT,
            fontSize: '18px',
            fontWeight: 'bold'
        },
        listing: {
            fill: WHITE,
            font: FONT,
            fontSize: '24px',
        },
        listing_scores: {
            fill: WHITE,
            font: FONT,
            fontSize: '24px',
            align: 'right'
        }
    },
    settings: {
        label: {
            fill: BLUE2,
            font: FONT,
            fontSize: '22px'
        }
    },
    register: {
        title: {
            fill: PURPLE,
            font: FONT,
            fontSize: '55px',
            align: 'center',
            fontWeight: 'bold'
        },
        instructions: {
            fill: BLUE,
            font: FONT,
            fontSize: '28px',
            align: 'left'
        },
        step_title: {
            fill: PURPLE,
            font: FONT,
            fontSize: '24px',
            fontWeight: 'bold'
        },
        step_number: {
            fill: WHITE,
            font: FONT,
            fontSize: '36px',
            fontWeight: 'bold'
        },
        submit_text: {
            fill: BLUE2,
            font: FONT,
            fontSize: '24px',
            fontWeight: 'bold'
        },
        privacy_policy: {
            fill: BLUE2,
            font: FONT,
            fontSize: '18px'
        }
    },
    countdown: {
        title: {
            fill: PURPLE,
            font: FONT,
            fontSize: '55px',
            fontWeight: 'bold'
        },
        number: {
            fill: GREEN,
            font: FONT,
            fontSize: '243px',
            fontWeight: 'bold',
            align: 'right'
        }
    },
    play: {
        timer_title: {
            fill: BLUE2,
            font: FONT,
            fontSize: '18px'
        },
        high_score: {
            fill: BLUE2,
            font: FONT,
            fontSize: '21px',
            fontWeight: 'bold'
        },
        high_score_name: {
            fill: BLUE2,
            font: FONT,
            fontSize: '21px',
        },
        score_label: {
            fill: RED2,
            font: FONT,
            fontSize: '21px',
            fontWeight: 'bold'
        },
        your_score: {
            fill: RED2,
            font: FONT,
            fontSize: '36px',
            fontWeight: '800'
        },
        time: {
            fill: WHITE,
            font: FONT,
            fontSize: '32px',
            fontWeight: 'bold'
        },
        question: {
            fill: BLUE,
            font: FONT,
            fontSize: '27px',
            fontWeight: 'bold'
        },
        answer_text: {
            fill: BLUE2,
            font: FONT,
            fontSize: '21px'
        },
        option: {
            fill: PURPLE,
            font: FONT,
            fontSize: '42px',
            fontWeight: 'bold'
        },
        option_small: {
            fill: PURPLE,
            font: FONT,
            fontSize: '21px',
            fontWeight: 'bold'
        },
        next_text: {
            fill: PURPLE,
            font: FONT,
            fontSize: '18px'
        }
    },
    finish: {
        title: {
            fill: PURPLE,
            font: FONT,
            fontSize: '55px',
            fontWeight: 'bold'
        },
        points: {
            fill: PINK,
            font: FONT,
            fontSize: '42px',
            fontWeight: 'bold'
        },
        message: {
            fill: BLUE2,
            font: FONT,
            fontSize: '28px'
        }
    }
};