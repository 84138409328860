interface Coordinates {
    x: number;
    y: number
}


export function cycleColumns(x_coordinates: number[], y_coordinates: number[], index: number): Coordinates {
    return x_coordinates.reduce((p, n) => {
        y_coordinates.map(m => p.push({ x: n, y: m }));
        return p
    }, [])[index]
}
