import {StageMode} from '../config/Stage.config';
export class TriviaGame extends Phaser.Game {
    stageScale: number;
    assetMode: StageMode;

    data: any;
    components: any;
    styles: any;
    audio: any;

    constructor(width, height, mode, element) {
        super(width, height, mode, element);
    }


}