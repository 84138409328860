/**
 * Configurable file that allows for the setting of asset settings
 * @type {{mobile: {sprites: string; map: string}; desktop: {sprites: string; map: string}; audio: string}}
 */

const BASE_URL = "http://localhost:8091/";

export const ASSETS = {
    mobile: {
        sprites: `${BASE_URL}assets/mobile.png`,
        map: `${BASE_URL}assets/mobile.json`
    },
    desktop: {
        sprites: `${BASE_URL}assets/desktop.png`,
        map: `${BASE_URL}assets/desktop.json`
    },
    audio: `${BASE_URL}assets/`
};