import {StageAssets, TriviaState} from '../base/State';
import {AssetPlacement, AssetType, getProperty, placeAsset, removeAsset, removeAssets} from '../utils/Asset.util';
import {formatTimer, zeroPad, LETTERS} from '../utils/Format.util';
import {DATA} from '../config/Data.config';
import {pickRandom} from '../utils/Random.util';
import {cycleColumns} from '../utils/Cycle.util';
import {hideLeaderboard, showLeaderboard} from '../components/Leaderboard.component';
import {showSettings} from '../components/Sound.component';

export class PlayState extends TriviaState {
    assets: StageAssets = {
        desktop: [
            {
                family: ['body', 'background'],
                type: AssetType.Image,
                position_x: 0,
                position_y: 0,
                collection: 'desktop',
                key: 'rays-bg'
            },
            {
                family: ['footer', 'timer', 'background'],
                type: AssetType.Image,
                position_x: 759,
                position_y: 389,
                collection: 'desktop',
                key: 'current-time-bg'
            },
            {
                family: ['footer', 'background'],
                type: AssetType.Image,
                position_x: 0,
                position_y: 451,
                collection: 'desktop',
                key: 'game-footer-bg'
            },
            {
                family: ['footer', 'border'],
                type: AssetType.Image,
                position_x: 0,
                position_y: 447,
                collection: 'desktop',
                key: 'footer-border-bottom'
            },
            {
                family: ['body', 'border'],
                type: AssetType.Image,
                position_x: 0,
                position_y: 0,
                collection: 'desktop',
                key: 'border-2px'
            },
            {
                family: ['body', 'watermark'],
                type: AssetType.Image,
                position_x: 41,
                position_y: 28,
                collection: 'desktop',
                key: 'game-logo-watermark'
            },
            {
                family: ['body', 'settings'],
                type: AssetType.Button,
                position_x: 856,
                position_y: 17,
                collection: 'desktop',
                key: 'settings-icon-1',
                callback: this.showSettings
            },
            {
                family: ['footer', 'timer', 'title'],
                type: AssetType.Text,
                position_x: 768,
                position_y: 367,
                collection: 'desktop',
                key: 'TIME REMAINING',
                styleFamily: ['play', 'timer_title']
            },
            {
                family: ['footer', 'highscore', 'label'],
                type: AssetType.Text,
                position_x: 22,
                position_y: 474,
                collection: 'desktop',
                key: 'HIGH SCORE:',
                styleFamily: ['play', 'high_score']
            },
            {
                family: ['footer', 'highscore', 'screenname'],
                type: AssetType.Text,
                position_x: 190,
                position_y: 474,
                collection: 'desktop',
                key: '',
                styleFamily: ['play', 'high_score_name']
            },
            {
                family: ['footer', 'score', 'label'],
                type: AssetType.Text,
                position_x: 667,
                position_y: 474,
                collection: 'desktop',
                key: 'YOUR SCORE:',
                styleFamily: ['play', 'score_label']
            },
            {
                family: ['footer', 'score', 'score'],
                type: AssetType.Text,
                position_x: 826,
                position_y: 466,
                collection: 'desktop',
                key: "",
                styleFamily: ['play', 'your_score']
            },
            {
                family: ['body', 'leadericon'],
                type: AssetType.Button,
                position_x: 432,
                position_y: 472,
                collection: 'desktop',
                key: 'leader-icon',
                callback: this.showLeaderboard
            },
            {
                family: ['footer', 'timer', 'time'],
                type: AssetType.Text,
                position_x: 780,
                position_y: 403,
                collection: 'desktop',
                key: '02:00:00',
                styleFamily: ['play', 'time']
            },
            {
                family: ['body', 'question'],
                type: AssetType.Text,
                position_x: 41,
                position_y: 127,
                width: 823,
                key: '',
                lineSpacing: -5,
                styleFamily: ['play', 'question']
            },

        ],
        mobile: []
    };

    clock_running: boolean;
    start: number;
    stop_time: number;
    pause_time: number;
    total_time: number;
    asked_questions: any[];
    question: any;
    debounce: boolean = false;

    constructor() {
        super();
    }

    create() {
        this.setup();
        // Set Name of High Score
        this.game.components.footer.highscore.screenname.setText(this.game.data.leaderboard[0].username);
        this.game.components.footer.score.score.setText(zeroPad(this.game.data.score, 3));
        this.total_time = 2 * 60 * 1000; // 2 minutes
        this.asked_questions = [];
        this.nextQuestion();

    }

    update() {
        if (this.clock_running) {
            this.tick();
            this.game.components.footer.score.score.setText(zeroPad(this.game.data.score, 3));
        } else {
            if (this.stop_time && this.pause_time) {

                const elapsed = this.game.time.time - this.stop_time,
                    ratio = elapsed / this.pause_time,
                    width = Math.min(Math.ceil(ratio * 755), 755);
                this.game.components.body.progress_bar.drawRect(0, 0, width, 10)
            }
        }
    }

    /**
     * Start the Timer
     */
    startClock() {
        this.clock_running = true;
        this.start = this.game.time.time;
        this.stop_time = null;
        this.pause_time = null;
    }

    /**
     * Stop the timer
     */
    stopClock() {
        if (this.start) {
            this.clock_running = false;
            this.stop_time = this.game.time.time;
            this.start = null;
        }
    }

    /**
     * Update the display
     */
    tick() {
        this.total_time -= this.game.time.time - this.start;
        this.start = this.game.time.time;
        if (this.total_time <= 0) {
            this.finish();
        }
        this.game.components.footer.timer.time.setText(formatTimer(this.total_time));
    }

    /**
     * Show the leaderboard
     */
    showLeaderboard() {
        showLeaderboard(this.game, this);
    }

    /**
     * SHow the audio settings
     */
    showSettings() {
        showSettings(this.game, this);
    }

    /**
     * Answer a question
     * @param answer
     */
    answer(answer) {
        if (this.debounce) {
            return;
        }
        this.debounce = true;
        this.game.audio['Question-Loop'].stop();
        this.game.audio['Select'].play();
        this.stopClock();
        this.asked_questions.push(this.question.id);
        $.ajax({
            url: DATA.answer,
            type: "PUT",
            data: JSON.stringify({
                user_id: this.game.data.user_id,
                question_id: this.question.id,
                answer_id: answer
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json"
        }).then(d => {
            this.game.components.body.watermark.visible = false;
            this.game.components.body.question.visible = false;
            placeAsset(this.game, this, {
                family: ['body', 'title'],
                type: AssetType.Image,
                position_x: 36,
                position_y: 24,
                collection: 'desktop',
                key: d.correct ? "CORRECT" : "INCORRECT"
            });
            placeAsset(this.game, this, {
                family: ['body', 'answer_text'],
                type: AssetType.Text,
                position_x: 37,
                position_y: 67,
                width: 743,
                styleFamily: ['play', 'answer_text'],
                key: d.answer_message
            });
            placeAsset(this.game, this, {
                family: ['body', 'answer_button'],
                type: AssetType.Button,
                position_x: 110,
                position_y: 382,
                collection: 'desktop',
                key: d.button_img,
                callback: () => {
                    window.open(d.source_link);
                }
            });
            Object.keys(this.game.components.body.options).map(o => {
                removeAssets(
                    this.game, [
                        ['body', 'options', o, 'button'],
                        ['body', 'options', o, 'text']
                    ]
                )
            });
            if (d.correct) {
                this.game.audio['Correct-Answer'].play();
            } else {
                this.game.audio['Incorrect-Answer'].play();
            }
            // Update Score
            this.game.data.score = Math.max((this.game.data.score + parseInt(d.points, 10)), 0);
            this.game.components.footer.score.score.setText(zeroPad(this.game.data.score, 3));
            const charcount = d.answer_message.length,
                countdown = charcount < 250 ? 5000 : charcount < 450 ? 8000 : 10000,
                timeout = setTimeout(() => {
                    this.nextQuestion()
                }, countdown);
            placeAsset(this.game, this, {
                family: ['body', 'next_text'],
                type: AssetType.Text,
                position_x: 850,
                position_y: 155,
                key: 'NEXT',
                styleFamily: ['play', 'next_text']
            });
            this.pause_time = countdown;
            placeAsset(this.game, this, {
                family: ['body', 'next_button'],
                type: AssetType.Button,
                position_x: 836,
                position_y: 181,
                collection: 'desktop',
                key: 'next-question',
                callback: () => {
                    clearTimeout(timeout);
                    this.nextQuestion();
                }
            });
            //  Phaser.Graphics.prototype.drawRect(4, 437, 1, 10);
            this.game.components.body.progress_bar = this.game.add.graphics(4, 437);
            this.game.components.body.progress_bar.beginFill(0x008184, 1);
            this.game.components.body.progress_bar.bounds = new PIXI.Rectangle(0, 0, 1, 10);
            this.game.components.body.progress_bar.drawRect(0, 0, 1, 10);
            this.debounce = false;

        });
    }

    /**
     * Load the Next Question
     */
    nextQuestion() {
        if (this.debounce) {
            return;
        }
        this.debounce = true;
        const columns = [41, 450]; // X positions
        const rows = [266, 343]; // Y positions;
        const text_offset = { x: 78, y: 34 };
        this.game.components.body.watermark.visible = true;
        try {
            this.game.components.body.answer_button.callback = null;
        } catch (e) {}
        removeAssets(this.game, [
            ['body', 'title'],
            ['body', 'answer_text'],
            ['body', 'answer_button'],
            ['body', 'next_text'],
            ['body', 'next_button'],
            ['body', 'progress_bar']
        ]);
        try {
            Object.keys(this.game.components.body.options).map(o => {
                console.info('body.options.map', o);
                removeAssets(this.game, [
                    ['body', 'options', o, 'button'],
                    ['body', 'options', o, 'text']
                ]);
            });
        } catch (ex) {
        }
        try {
            hideLeaderboard(this.game);
        } catch (ex) {
        }
        this.question = pickRandom(this.game.data.questions.filter(q => this.asked_questions.indexOf(q.id) === -1));
        if (!this.question) {
            this.game.audio['Question-Loop'].stop();
            this.finish();
        }
        this.game.audio['Question-Loop'].loopFull();
        this.game.components.body.question.visible = true;
        this.game.components.body.question.setText(this.question.question_text);
        this.question.options.map((o, i) => {
            const letter = LETTERS[i].toLowerCase();
            const coordinates = cycleColumns(columns, rows, i);
            let options: AssetPlacement[] = [
                {
                    family: ['body', 'options', letter, 'button'],
                    type: AssetType.Button,
                    position_x: coordinates.x,
                    position_y: coordinates.y,
                    collection: 'desktop',
                    key: `${letter}-base`,
                    callback: () => {
                        this.answer(o.id)
                    }
                },
                {
                    family: ['body', 'options', letter, 'text'],
                    type: AssetType.Text,
                    position_x: coordinates.x + text_offset.x,
                    position_y: coordinates.y + text_offset.y,
                    collection: 'desktop',
                    anchor: [0, 0.5],
                    key: o.text,
                    width: 300,
                    lineSpacing: -5,
                    styleFamily: ['play', Math.max(...this.question.options.map(k => k.text.length)) < 10 ? 'option' : 'option_small']
                }
            ];

            options.map(a => placeAsset(this.game, this, a));
        });
        this.startClock();
        this.debounce = false;
    }

    /**
     * Finish answering questions
     */
    finish() {
        $.ajax({
            url: DATA.finish,
            type: "POST",
            data: JSON.stringify({
                user_id: this.game.data.user_id
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
        });
        this.game.state.start('Finish')
    }

}