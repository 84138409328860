import {TriviaGame} from '../base/Game';
import {TriviaState} from '../base/State';
import {AssetPlacement, AssetType, placeAsset, removeAsset} from '../utils/Asset.util';

/**
 * Hide The Leaderboard and play close sound.
 * @param gameRef
 */
export function hideLeaderboard(gameRef: TriviaGame): void {
    Object.keys(gameRef.components.body.leaderboard).map(c => {
        try {
            removeAsset(gameRef, ['body', 'leaderboard', c]);
        } catch (ex) {
            // Suppress Errors
        }
    });
    gameRef.audio['Submit-or-Toggle'].play();
    try {
        gameRef.components.body.leadericon.inputEnabled = true;
    } catch (ex) {
    }
}

/**
 * Show the Leadeboard
 * @param gameRef
 * @param stateRef
 */
export function showLeaderboard(gameRef: TriviaGame, stateRef: TriviaState): void {
    let [leader, ...rest] = gameRef.data.leaderboard;
    rest = rest.slice(0, 5);
    try {
        gameRef.components.body.leadericon.inputEnabled = false;
    } catch (ex) {
    }
    gameRef.audio['Leaderboard-Show'].play();
    const background: AssetPlacement[] = [
        {
            family: ['body', 'leaderboard', 'background'],
            type: AssetType.Image,
            position_x: 115,
            position_y: 45,
            collection: 'desktop',
            key: 'leaderboard-overlay'
        },
        {
            family: ['body', 'leaderboard', 'current_leader'],
            type: AssetType.Text,
            position_x: 192,
            position_y: 111,
            key: 'CURRENT LEADER',
            styleFamily: ['leaderboard', 'header']
        },
        {
            family: ['body', 'leaderboard', 'hunt'],
            type: AssetType.Text,
            position_x: 192,
            position_y: 203,
            key: 'IN THE HUNT',
            styleFamily: ['leaderboard', 'header']
        },
        {
            family: ['body', 'leaderboard', 'point'],
            type: AssetType.Text,
            position_x: 617,
            position_y: 203,
            key: 'POINT TOTAL',
            styleFamily: ['leaderboard', 'header']
        },
        {
            family: ['body', 'leaderboard', 'current_leader_user'],
            type: AssetType.Text,
            position_x: 192,
            position_y: 137,
            key: `${leader.username} - ${leader.score} points`,
            styleFamily: ['leaderboard', 'listing']
        },
        {
            family: ['body', 'leaderboard', 'hunt_usernames'],
            type: AssetType.Text,
            position_x: 192,
            position_y: 230,
            key: rest.reduce((a, c, i) => a + `${i + 2}. ${c.username}\n`, ''),
            styleFamily: ['leaderboard', 'listing']
        },
        {
            family: ['body', 'leaderboard', 'hunt_scores'],
            type: AssetType.Text,
            position_x: 696,
            position_y: 230,
            key: rest.reduce((a, c) => a + `${c.score}\n`, ''),
            styleFamily: ['leaderboard', 'listing_scores']
        },
        {
            family: ['body', 'leaderboard', 'close'],
            type: AssetType.Button,
            position_x: 434,
            position_y: 405,
            collection: 'desktop',
            key: 'close-leaderboard-btn',
            callback: () => hideLeaderboard(gameRef)
        },
    ];
    background.map(asset => placeAsset(gameRef, stateRef, asset));
}