import {StageAssets, TriviaState} from '../base/State';
import {AssetType} from '../utils/Asset.util';
import {Input} from '../utils/Input.util';
import {DATA} from '../config/Data.config';
import {showSettings} from '../components/Sound.component';

export class RegisterState extends TriviaState {
    email: Input;
    name: Input;
    assets: StageAssets = {
        desktop: [
            {
                family: ['body', 'background'],
                type: AssetType.Image,
                position_x: 0,
                position_y: 0,
                collection: 'desktop',
                key: 'rays-bg'
            },
            {
                family: ['body', 'border'],
                type: AssetType.Image,
                position_x: 0,
                position_y: 0,
                collection: 'desktop',
                key: 'border-2px'
            },
            {
                family: ['body', 'watermark'],
                type: AssetType.Image,
                position_x: 41,
                position_y: 28,
                collection: 'desktop',
                key: 'game-logo-watermark'
            },
            {
                family: ['body', 'settings'],
                type: AssetType.Button,
                position_x: 856,
                position_y: 17,
                collection: 'desktop',
                key: 'settings-icon-1',
                callback: this.showSettings
            },
            {
                family: ['body', 'title'],
                type: AssetType.Text,
                position_x: 446,
                position_y: 50,
                collection: 'desktop',
                key: "IT'S GO TIME!",
                styleFamily: ['register', 'title']
            },
            {
                family: ['body', 'instructions'],
                type: AssetType.Text,
                position_x: 40,
                position_y: 143,
                collection: 'desktop',
                key: "You have 2 minutes to answer as many questions as you can. Each correct answer is worth 10 points, and each incorrect answer will subtract 3 points from your total score. ",
                width: 835,
                styleFamily: ['register', 'instructions']
            },
            {
                family: ['body', 'step_1'],
                type: AssetType.Text,
                position_x: 91,
                position_y: 268,
                collection: 'desktop',
                key: "Enter your email.*",
                styleFamily: ['register', 'step_title']
            },
            {
                family: ['body', 'step_2'],
                type: AssetType.Text,
                position_x: 509,
                position_y: 268,
                collection: 'desktop',
                key: "Choose a screen name.",
                styleFamily: ['register', 'step_title']
            },
            {
                family: ['body', 'field_1'],
                type: AssetType.Image,
                position_x: 87,
                position_y: 304,
                collection: 'desktop',
                key: "field1"
            },
            {
                family: ['body', 'field_2'],
                type: AssetType.Image,
                position_x: 508,
                position_y: 304,
                collection: 'desktop',
                key: "field2"
            },
            {
                family: ['body', 'field_circle_1'],
                type: AssetType.Image,
                position_x: 41,
                position_y: 308,
                collection: 'desktop',
                key: "btn-bg-base"
            },
            {
                family: ['body', 'field_circle_2'],
                type: AssetType.Image,
                position_x: 466,
                position_y: 308,
                collection: 'desktop',
                key: "btn-bg-base"
            },
            {
                family: ['body', 'field_circle_3'],
                type: AssetType.Image,
                position_x: 84,
                position_y: 403,
                collection: 'desktop',
                key: "btn-bg-base"
            },
            {
                family: ['body', 'field_circle_num_1'],
                type: AssetType.Text,
                position_x: 62,
                position_y: 320,
                collection: 'desktop',
                key: "1",
                styleFamily: ['register', 'step_number']
            },
            {
                family: ['body', 'field_circle_num_2'],
                type: AssetType.Text,
                position_x: 488,
                position_y: 320,
                collection: 'desktop',
                key: "2",
                styleFamily: ['register', 'step_number']
            },
            {
                family: ['body', 'field_circle_num_3'],
                type: AssetType.Text,
                position_x: 107,
                position_y: 415,
                collection: 'desktop',
                key: "3",
                styleFamily: ['register', 'step_number']
            },
            {
                family: ['body', 'field_circle_num_2'],
                type: AssetType.Text,
                position_x: 164,
                position_y: 426,
                collection: 'desktop',
                key: "Submit your information and get started!",
                styleFamily: ['register', 'submit_text']
            },
            {
                family: ['body', 'play'],
                type: AssetType.Button,
                position_x: 669,
                position_y: 408,
                collection: 'desktop',
                key: "submit-btn",
                callback: this.register,
                styleFamily: ['register', 'submit_text']
            },
            {
                family: ['body', 'privacy_policy'],
                type: AssetType.Text,
                position_x: 79,
                position_y: 490,
                collection: 'desktop',
                key: "*We won’t share or sell your information to anybody else. See our Privacy Policy for details.",
                styleFamily: ['register', 'privacy_policy']
            },
        ],
        mobile: []
    };

    constructor() {
        super();
    }

    create() {
        this.setup();

        this.game.components.body.privacy_policy.inputEnabled = true;
        this.game.components.body.privacy_policy.events.onInputDown.add(this.privacy_policy, this);

        // Setup Input Fields
        this.email = new Input(this.game, {
            name: 'email',
            x: 105,
            y: 310,
            width: 300,
            height: 60,
            placeholder: 'Email Address',
            color: 'rgba(0,0,0,1)'
        });
        this.name = new Input(this.game, {
            name: 'name',
            x: 550,
            y: 310,
            width: 300,
            height: 60,
            placeholder: 'Screen Name',
            color: 'rgba(0,0,0,1)'
        });
        this.email.show();
        this.name.show();
    }

    register() {
        this.game.audio['Submit-or-Toggle'].play();
        if (this.email.getValue() && this.name.getValue()) {
            this.game.data.email = this.email.getValue();
            this.game.data.name = this.name.getValue();
            $.ajax({
                url: DATA.register,
                type: 'PUT',
                data: JSON.stringify({email: this.game.data.email, username: this.game.data.name}),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: (data) => {
                    console.log(`Session Id: ${data.id}`);
                    this.game.data.user_id = data.id;
                }
            });
            this.email.destroy();
            this.name.destroy();
            if (!this.game.device.desktop && !this.game.device.iOS) {
                try {
                    this.goFull();
                } catch (ex) {
                    console.info('Error going fullscreen', ex);
                }
            }
            this.game.state.start('Countdown');
        }
    }

    showSettings () {
        showSettings(this.game, this);
    }

    privacy_policy() {
        window.open("https://www.psoriasis.org/about-us/legal/privacy-policy");
    }
}