export function formatTimer(nanoseconds: number): string {
    const minutes      = Math.floor(nanoseconds / 60000) % 60,
          seconds      = Math.floor(nanoseconds / 1000) % 60,
          milliseconds = Math.floor(nanoseconds) % 100,
          min_f        = minutes >= 10 ? `${minutes}` : `0${minutes}`,
          sec_f        = seconds >= 10 ? `${seconds}`: `0${seconds}`,
          mil_f        = milliseconds > 10 ? `${milliseconds}` : `0${milliseconds}`;
    return `${min_f}:${sec_f}:${mil_f}`;
}

export function zeroPad(number: any, width: number): string {
    number = `${number}`;
    return number.length >= width ? `${number}` : new Array(width - number.length + 1).join('0') + number;
}

export const LETTERS = ['A', 'B', 'C', 'D'];