/// <reference path="../node_modules/phaser-ce/typescript/phaser.comments.d.ts" />

import {BootState} from "./game/Boot.state";
import {LandingState} from './game/Landing.state';
import {PlayState} from './game/Play.state';
import {RegisterState} from './game/Register.state';
import {FinishState} from './game/Finish.state';
import {STAGE} from './config/Stage.config';
import {getGameSize} from './utils/Scaling.util';
import {TriviaGame} from './base/Game';
import {CountdownState} from './game/Countdown.state';

module SkinInTheGame {
    /**
     * Main Game Instance
     */
    export class MyGame {
        game: TriviaGame;

        /**
         * Construct Game Instance
         */
        constructor() {
            const gameSize = getGameSize(STAGE);
            this.game = new TriviaGame(gameSize.width, gameSize.height, Phaser.AUTO, STAGE.element);
            this.game.stageScale = gameSize.scale;
            this.game.assetMode = gameSize.mode;

            this.addStates();
            this.start();
        }

        /**
         * Add Game States to Game
         */
        addStates() {
            this.game.state.add("Boot", BootState, false);
            this.game.state.add("Landing", LandingState, false);
            this.game.state.add("Register", RegisterState, false);
            this.game.state.add("Countdown", CountdownState, false);
            this.game.state.add("Play", PlayState, false);
            this.game.state.add("Finish", FinishState, false);
        }

        /**
         * Start Boot State
         */
        start() {

            this.game.state.start("Boot");
        }
    }
}


window.onload = () => {
    // Setup stage styles for inputs
    const stage = <HTMLElement>document.querySelector('#stage');
    stage.style.position = 'relative';
    const game = new SkinInTheGame.MyGame();
    (<any>window).game = game;
    (<any>window).WebFontConfig =  {
        active: () => {
            window['game']['game']['time']['events'].add(Phaser.Timer.SECOND, () => {console.info("Fonts Loaded")}, this);
        },

        google: {
            families: ['Open Sans', 'Open Sans Condensed']
        }
    };
};