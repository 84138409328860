import {StageAssets, TriviaState} from '../base/State';
import {AssetType} from "../utils/Asset.util";
import {zeroPad} from '../utils/Format.util';
import {showLeaderboard} from '../components/Leaderboard.component';
import {showSettings} from '../components/Sound.component';

export class FinishState extends TriviaState {
    assets: StageAssets = {
        desktop: [
            {
                family: ['body', 'background'],
                type: AssetType.Image,
                position_x: 0,
                position_y: 0,
                collection: 'desktop',
                key: 'rays-bg'
            },
            {
                family: ['body', 'people', 'yellow'],
                type: AssetType.Image,
                position_x: 22,
                position_y: 141,
                collection: 'desktop',
                key: 'lady-yellow-shirt'
            },
            {
                family: ['body', 'people', 'blue'],
                type: AssetType.Image,
                position_x: 462,
                position_y: 128,
                collection: 'desktop',
                key: 'man-blue-shirt'
            },
            {
                family: ['body', 'people', 'pink'],
                type: AssetType.Image,
                position_x: 706,
                position_y: 190,
                collection: 'desktop',
                key: 'lady-pink-shirt'
            },
            {
                family: ['body', 'border'],
                type: AssetType.Image,
                position_x: 0,
                position_y: 0,
                collection: 'desktop',
                key: 'border-2px'
            },
            {
                family: ['body', 'watermark'],
                type: AssetType.Image,
                position_x: 41,
                position_y: 28,
                collection: 'desktop',
                key: 'game-logo-watermark'
            },
            {
                family: ['body', 'settings'],
                type: AssetType.Button,
                position_x: 856,
                position_y: 17,
                collection: 'desktop',
                key: 'settings-icon-1',
                callback: this.showSettings
            },
            {
                family: ['body', 'title'],
                type: AssetType.Text,
                position_x: 478,
                position_y: 50,
                key: '',
                styleFamily: ['finish', 'title']
            },
            {
                family: ['body', 'points'],
                type: AssetType.Text,
                position_x: 197,
                position_y: 197,
                key: '',
                styleFamily: ['finish', 'points']
            },
            {
                family: ['body', 'message'],
                type: AssetType.Text,
                position_x: 197,
                position_y: 260,
                width: 300,
                key: '',
                styleFamily: ['finish', 'message']
            },
            {
                family: ['body', 'leadericon'],
                type: AssetType.Button,
                position_x: 785,
                position_y: 472,
                collection: 'desktop',
                key: 'leader-icon',
                callback: this.showLeaderboard
            },
            {
                family: ['body', 'play_again'],
                type: AssetType.Button,
                position_x: 250,
                position_y: 389,
                collection: 'desktop',
                key: 'play-again-btn',
                callback: this.playAgain
            },
        ],
        mobile: []
    };

    constructor() {
        super();
    }

    create() {
        this.setup();
        const filtered_leaderboard = this.game.data.leaderboard.filter(l => !isNaN(+l.score)).map(l => +l.score);
        const low_leaderboard = Math.min.apply(null, filtered_leaderboard);
        const high_leaderboard = Math.max.apply(null, filtered_leaderboard);
        this.game.audio['Question-Loop'].stop();

        this.game.components.body.points.setText(`${this.game.data.score} POINTS!`);

        if (this.game.data.score > low_leaderboard) {
            if (this.game.data.score > high_leaderboard) {
                this.game.components.body.title.setText('WOO HOO!');
                this.game.components.body.message.setText('Great game! You\'ve got the top score!');
            } else {
                this.game.components.body.title.setText('OH YEAH!');
                this.game.components.body.message.setText('Congrats! You\'ve made the leader board!');
            }
            this.game.audio['Game-Over-Times-Up-Leader-Winner'].play();
        } else {
            this.game.audio['Game-Over-Times-Up-Not-Leader'].play();
            this.game.components.body.title.setText('NICE TRY!');
            this.game.components.body.message.setText('Sorry, you didn\'t make the leader board this time.');
        }
    }

    showLeaderboard() {
        showLeaderboard(this.game, this);
    }

    showSettings() {
        showSettings(this.game, this);
    }

    playAgain() {
        this.game.audio['Submit-or-Toggle'].play();
        this.game.state.start('Boot');
    }

}