/**
 * Configuration for the Game Stage.
 */

export interface StageConst {
    width: number;
    height: number;
    element: string;
    mobile_width?: number;
    mobile_height?: number;
}

export enum StageMode {
    Desktop,
    Mobile
}

export const STAGE: StageConst = {
    width: 924,
    height: 520,
    mobile_height: 1,
    mobile_width: 1,
    element: 'stage'
};