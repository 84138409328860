import {TriviaGame} from '../base/Game';
import {DATA} from '../config/Data.config';
import {StageMode} from '../config/Stage.config';
import {ASSETS} from '../config/Assets.config';
import {STYLES} from '../config/Styles.config';
import {AUDIO_FILES} from '../config/Audio.config';

/**
 * BootState.
 *
 * The Base state for Loading in the Games Assets and setting up run.
 */
export class BootState extends Phaser.State {
    game: TriviaGame;

    constructor() {
        super();
    }

    /**
     * Load all of the assets.
     */
    preload() {
        this.game.load.crossOrigin = 'anonymous';
        this.game.load.json('leaderboard', DATA.leaderboard);
        this.game.load.json('questions', DATA.questions);
        this.game.load.script('webfont', '//ajax.googleapis.com/ajax/libs/webfont/1.4.7/webfont.js');

        this.game.input.touch.preventDefault = false;

        switch (this.game.assetMode) {
            case StageMode.Desktop:
                this.game.load.atlasJSONHash('desktop', ASSETS.desktop.sprites, ASSETS.desktop.map);
                break;
            case StageMode.Mobile:
                this.game.load.atlasJSONHash('mobile', ASSETS.mobile.sprites, ASSETS.mobile.map);
                break;
        }

        AUDIO_FILES.map(name => {
          this.game.load.audio(name, [`${ASSETS.audio}${name}.mp3`, `${ASSETS.audio}${name}.ogg`])
        })

    }

    /**
     * Prep the game for running.
     */
    create() {
        this.game.stage.backgroundColor = 0xffffff;
        this.game.data = {name: '', email: '', score: 0};
        this.game.data.leaderboard = this.game.cache.getJSON('leaderboard');
        this.game.data.questions = this.game.cache.getJSON('questions');
        this.game.data.score = 0;
        while (this.game.data.leaderboard.length <= 5) {
            this.game.data.leaderboard.push({
                username: "---",
                score: "000",
            })
        }

        this.game.components = {
            body: {},
            footer: {}
        };
        this.game.audio = {};
        AUDIO_FILES.map(audio =>{
            this.game.audio[audio] = this.game.add.audio(audio);
        });
        this.game.styles = STYLES;

        this.game.state.start('Landing');
    }

}