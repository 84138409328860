import {TriviaGame} from './Game';
import {StageMode} from '../config/Stage.config';
import {placeAsset, AssetPlacement} from '../utils/Asset.util';


export interface StageAssets {
    desktop: AssetPlacement[];
    mobile: AssetPlacement[];
}

export class TriviaState extends Phaser.State {
    game: TriviaGame;

    assets: StageAssets;

    constructor() {
        super();
    }

    /**
     * Display all the assets in stage assets.
     */
    setup() {
        switch(this.game.assetMode) {
            case StageMode.Desktop:
                this.assets.desktop.map(info => placeAsset(this.game, this, info));
                break;
            case StageMode.Mobile:
                this.assets.mobile.map(info => placeAsset(this.game, this, info));
                break;
        }
    }

    goFull()  {
        console.info('starting full screen');
        if (this.game.scale.isFullScreen) {
            // this.game.scale.stopFullScreen();
        } else {
            this.game.scale.fullScreenScaleMode = Phaser.ScaleManager.SHOW_ALL;
            this.game.scale.startFullScreen(false);
        }

    }
}