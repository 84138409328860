import {TriviaGame} from '../base/Game';
import {TriviaState} from '../base/State';
export enum AssetType {
    Image,
    Text,
    Button,
}

export interface AssetPlacement {
    family: string[];
    type: AssetType;
    position_x: number;
    position_y: number;
    collection?: string;
    key: string;
    styleFamily?: string[];
    callback?: any;
    width?: number;
    lineSpacing?: number;
    anchor?: number[];
}

export function setProperty(obj: Object, keypath: string[], value: any) {
    let i   = 0,
        len = keypath.length - 1;
    for (; i < len; i++) {
        if (!obj.hasOwnProperty(keypath[i])) {
            obj[keypath[i]] = {};
        }
        obj = obj[keypath[i]];
    }
    obj[keypath[i]] = value;
}

export function getProperty(obj: Object, keypath: string[]): any {
    for (let i = 0; i < keypath.length; i++) {
        obj = obj[keypath[i]];
    }
    return obj
}

function placeImage(gameRef: TriviaGame, assetInfo: AssetPlacement) {
    let image = gameRef.add.image(
        assetInfo.position_x * gameRef.stageScale,
        assetInfo.position_y * gameRef.stageScale,
        assetInfo.collection,
        assetInfo.key
    );
    image.scale.setTo(gameRef.stageScale);
    setProperty(gameRef.components, assetInfo.family, image);
}

function placeText(gameRef: TriviaGame, assetInfo: AssetPlacement) {
    let text = gameRef.add.text(
        assetInfo.position_x * gameRef.stageScale,
        assetInfo.position_y * gameRef.stageScale,
        assetInfo.key,
        getProperty(gameRef.styles, assetInfo.styleFamily)
    );
    text.scale.setTo(gameRef.stageScale);
    if (assetInfo.width) {
        text.wordWrap = true;
        text.wordWrapWidth = assetInfo.width;
    }
    if (assetInfo.anchor) {
        text.anchor.set(assetInfo.anchor[0], assetInfo.anchor[1])
    } else {
        text.anchor.set(0, 0);
    }
    if (assetInfo.lineSpacing) {
        text.lineSpacing = assetInfo.lineSpacing;
    }

    setProperty(gameRef.components, assetInfo.family, text);
}

function placeButton(gameRef: TriviaGame, stateRef: TriviaState, assetInfo: AssetPlacement) {
    let button = gameRef.add.button(
        assetInfo.position_x * gameRef.stageScale,
        assetInfo.position_y * gameRef.stageScale,
        assetInfo.collection,
        assetInfo.callback,
        stateRef,
        assetInfo.key,
        assetInfo.key
    );
    button.scale.setTo(gameRef.stageScale);
    setProperty(gameRef.components, assetInfo.family, button);
}

export function placeAsset(gameRef: TriviaGame, stateRef: TriviaState, assetInfo: AssetPlacement) {
    switch (assetInfo.type) {
        case AssetType.Image:
            placeImage(gameRef, assetInfo);
            break;
        case AssetType.Text:
            placeText(gameRef, assetInfo);
            break;
        case AssetType.Button:
            placeButton(gameRef, stateRef, assetInfo);
            break;
    }
}

export function removeAsset(gameRef: TriviaGame, family: string[]): void {
    const obj = getProperty(gameRef.components, family);
    obj.invisible = false;
    obj.kill();
    obj.destroy();
}

export function removeAssets(gameRef: TriviaGame, families: string[][]): void {
    families.map((f) => {
        try {
            removeAsset(gameRef, f);
        } catch (e) {
            console.info('failed to remove asset', e);
            // do Nothing
        }
    })
}