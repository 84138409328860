import {StageConst, StageMode} from '../config/Stage.config';


/**
 * GameSize Interface
 */
interface GameSize {
    width: number;
    height: number;
    scale: number;
    mode: StageMode;
}

/**
 * getGameSize
 *
 * Given a configured StageConst, returns the calculated size of the game as a GameSize interface
 * @param stage
 * @returns {{width: any, height: any, scale: any, mode: any}}
 */
export function getGameSize(stage: StageConst): GameSize {
    const el = document.getElementById(stage.element);
    let width, height, scale, mode;
    if (el.offsetWidth > stage.width) {
        width = stage.width;
        height = stage.height;
        scale = 1;
        mode = StageMode.Desktop;
    } else if (el.offsetWidth <= stage.width && el.offsetWidth > stage.mobile_width) {
        width = el.offsetWidth;
        scale = el.offsetWidth / stage.width;
        height = stage.height * scale;
        mode = StageMode.Desktop;
    } else if (el.offsetWidth <= stage.mobile_width) {
        width = el.offsetWidth;
        scale = el.offsetWidth / stage.mobile_width;
        height = stage.mobile_height * scale;
        mode = StageMode.Mobile;
    }
    return {
        width: width,
        height: height,
        scale: scale,
        mode: mode
    }
}