import {StageAssets, TriviaState} from '../base/State';
import {AssetType, removeAsset} from '../utils/Asset.util';
import {showSettings} from '../components/Sound.component';

/**
 * CountdownState
 *
 * Show the numbers running up to the Play State.
 */
export class CountdownState extends TriviaState {
    assets: StageAssets = {
        desktop: [
            {
                family: ['body', 'background'],
                type: AssetType.Image,
                position_x: 0,
                position_y: 0,
                collection: 'desktop',
                key: 'rays-bg'
            },
            {
                family: ['body', 'border'],
                type: AssetType.Image,
                position_x: 0,
                position_y: 0,
                collection: 'desktop',
                key: 'border-2px'
            },
            {
                family: ['body', 'watermark'],
                type: AssetType.Image,
                position_x: 41,
                position_y: 28,
                collection: 'desktop',
                key: 'game-logo-watermark'
            },
            {
                family: ['body', 'settings'],
                type: AssetType.Button,
                position_x: 856,
                position_y: 17,
                collection: 'desktop',
                key: 'settings-icon-1',
                callback: this.showSettings
            },
            {
                family: ['body', 'title'],
                type: AssetType.Text,
                position_x: 88,
                position_y: 248,
                collection: 'desktop',
                key: "GET READY!",
                styleFamily: ['countdown', 'title']
            },
            {
                family: ['body', 'countdown_number'],
                type: AssetType.Text,
                position_x: 620,
                position_y: 180,
                collection: 'desktop',
                key: "",
                styleFamily: ['countdown', 'number'],
                anchor: [0.5,0]
            },

        ],
        mobile: []
    };
    count: number;
    debounce: boolean = false;
    constructor() {
        super();
    }

    create() {
        this.setup();

        this.game.audio['Music-Bed-Skin-In-the-Game'].stop();
        this.count = 3;
        this.updateCountdown();
    }

    /**
     * Update the countdown and set a refresh for 1 second
     */
    updateCountdown() {
        if (this.debounce) {
            return;
        }
        this.debounce = true;
        if (this.count > 0) {
            this.game.audio['Countdown-Bleep'].play();
            this.game.components.body.countdown_number.setText(this.count);
        } else if (this.count == 0) {
            removeAsset(this.game, ['body', 'title']);
            this.game.audio['Go'].play();
            this.game.components.body.countdown_number.setText("GO!");
        } else {
            this.play()
        }
        this.count -= 1;
        this.game.time.events.add(Phaser.Timer.SECOND, this.updateCountdown, this);
        this.debounce = false;
    }

    /**
     * Start the play state.
     */
    play() {
        this.game.state.start('Play');
    }

    /**
     * Show the audio settings.
     */
    showSettings () {
        showSettings(this.game, this);
    }
}