import {TriviaGame} from '../base/Game';
import {STAGE} from '../config/Stage.config';

export interface InputOptions {
    name: string;
    placeholder?: string;
    x: number;
    y: number;
    width: number;
    height: number;
    color: string;
}

export class Input {
    options: InputOptions;
    input: HTMLInputElement;
    game: TriviaGame;
    stage: HTMLElement;
    created: boolean;
    value: string;
    element: HTMLInputElement;

    constructor(gameRef: TriviaGame, options: InputOptions) {
        this.options = options;
        this.game = gameRef;
        this.input = document.createElement('input');
        this.value = '';
        this.stage = document.getElementById(STAGE.element);
    }

    create() {
        this.input.id = this.input.name = this.options.name;
        this.input.placeholder = this.options.placeholder || '';
        this.input.style.position = 'absolute';
        this.input.style.border = 'none';
        this.input.style.left = `${this.options.x * this.game.stageScale}px`;
        this.input.style.top = `${this.options.y * this.game.stageScale}px`;
        this.input.style.width = `${(this.options.width * this.game.stageScale) - 30}px`;
        this.input.style.height = `${this.options.height * this.game.stageScale}px`;
        this.input.style.padding = '0 15px';
        this.input.style.color = this.options.color;
        this.created = true;
        return this.input;
    }

    show() {
        if (!this.created) {
            this.create();
        }
        this.element = this.stage.appendChild(this.input);
    }

    hide() {
        this.element.style.display = 'none';
    }

    getValue () {
        return this.element.value;
    }

    setValue(value: string) {
        this.element.value = value;
    }

    destroy() {
        this.element.parentNode.removeChild(this.element);
    }
}