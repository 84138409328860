/// <reference path="../../node_modules/phaser-ce/typescript/phaser.comments.d.ts" />

import {StageAssets, TriviaState} from '../base/State';
import {AssetType} from '../utils/Asset.util';
import {showLeaderboard} from '../components/Leaderboard.component';

export class LandingState extends TriviaState {
    assets: StageAssets = {
        desktop: [
            {
                family: ['body', 'background'],
                type: AssetType.Image,
                position_x: 0,
                position_y: 0,
                collection: 'desktop',
                key: 'game-splash-BG',
            },
            {
                family: ['body', 'title'],
                type: AssetType.Image,
                position_x: 170,
                position_y: 55,
                collection: 'desktop',
                key: 'game-title'
            },
            {
                family: ['body', 'leadericon'],
                type: AssetType.Button,
                position_x: 88,
                position_y: 472,
                collection: 'desktop',
                key: 'leader-icon',
                callback: this.showLeaderboard
            },
            {
                family: ['body', 'subtitle'],
                type: AssetType.Image,
                position_x: 447,
                position_y: 457,
                collection: 'desktop',
                key: 'the-psoriatic-trivia-game'
            },
            {
                family: ['body', 'play_button'],
                type: AssetType.Button,
                position_x: 512,
                position_y: 271,
                collection: 'desktop',
                key: 'play-now',
                callback: () => {this.startGame()}
            },
            {
                family: ['body', 'phone_rotate'],
                type: AssetType.Image,
                position_x: 716,
                position_y: 33,
                collection: 'desktop',
                key: 'mobile-users-icon',
            },

        ],
        mobile: []
    };

    constructor() {
        super();
    }

    create() {
        console.info('Landing Created');
        this.setup();
        if (!this.game.device.desktop && this.game.stageScale < 0.7) {
            this.game.input.onTap.add(this.openGameInNewWindow, this);
        }
        if (this.game.device.desktop) {
            this.game.components.body.phone_rotate.visible = false;
        }
        this.game.audio['Music-Bed-Skin-In-the-Game'].loopFull(0.2);
        // remove spinner if it exists
        try {
            $('.spinner').remove();
        } catch (e){
            //
        }
    }

    startGame() {
        this.game.audio['Submit-or-Toggle'].play();
        this.game.state.start('Register');
    }

    showLeaderboard() {
        showLeaderboard(this.game, this);
    }

    openGameInNewWindow() {
        window.open("https://media.awarenessmonthly.com")
    }

}