import {TriviaGame} from '../base/Game';
import {AssetPlacement, AssetType, placeAsset, removeAsset} from '../utils/Asset.util';
import {TriviaState} from '../base/State';

function panel_on(gameRef: TriviaGame, stateRef: TriviaState): AssetPlacement[] {
    return [
        {
            family: ['body', 'settings', 'toggle_on_bg'],
            type: AssetType.Button,
            position_x: 645,
            position_y: 21,
            collection: 'desktop',
            key: 'toggle-bg-OFF',
            callback: () => enableSound(gameRef, stateRef)
        },
        {
            family: ['body', 'settings', 'toggle_on_btn'],
            type: AssetType.Button,
            position_x: 650,
            position_y: 26,
            collection: 'desktop',
            key: 'toggle-btn-OFF',
            callback: () => enableSound(gameRef, stateRef)
        },
    ];
}

function panel_off(gameRef: TriviaGame, stateRef: TriviaState): AssetPlacement[] {
    return [
        {
            family: ['body', 'settings', 'toggle_off_bg'],
            type: AssetType.Button,
            position_x: 645,
            position_y: 21,
            collection: 'desktop',
            key: 'toggle-bg-ON',
            callback: () => disableSound(gameRef, stateRef)
        },
        {
            family: ['body', 'settings', 'toggle_off_btn'],
            type: AssetType.Button,
            position_x: 693,
            position_y: 26,
            collection: 'desktop',
            key: 'toggle-btn-ON',
            callback: () => disableSound(gameRef, stateRef)
        },
    ];
}
/**
 * Enable Sound for the Game
 * @param gameRef
 * @param stateRef
 */
function enableSound(gameRef: TriviaGame, stateRef: TriviaState): void {
    gameRef.sound.mute = false;
    try {
        removeAsset(gameRef, ['body', 'settings', 'toggle_on_bg']);
        removeAsset(gameRef, ['body', 'settings', 'toggle_on_btn']);
    } catch (ex) {
        // Suppress Errors
    }
    panel_off(gameRef, stateRef).map(a => placeAsset(gameRef, stateRef, a));
}

/**
 * Disable sound for the game
 * @param gameRef
 * @param stateRef
 */
function disableSound(gameRef: TriviaGame, stateRef: TriviaState): void {
    gameRef.sound.mute = true;
    try {
        removeAsset(gameRef, ['body', 'settings', 'toggle_off_bg']);
        removeAsset(gameRef, ['body', 'settings', 'toggle_off_btn']);
    } catch (ex) {
        // Suppress Errors
    }
    panel_on(gameRef, stateRef).map(a => placeAsset(gameRef, stateRef, a));
}

/**
 * Hide Audio settings
 * @param gameRef
 */
function hideSettings(gameRef: TriviaGame) {
    console.log('here');

    Object.keys(gameRef.components.body.settings).map(c => {
        try {
            removeAsset(gameRef, ['body', 'settings', c]);
        } catch (ex) {
            // Suppress Key Errors
        }
    });
    gameRef.audio['Submit-or-Toggle'].play()
}

/**
 * SHow the audio settings panel
 * @param gameRef
 * @param stateRef
 */
export function showSettings(gameRef: TriviaGame, stateRef: TriviaState) {
    gameRef.audio['Submit-or-Toggle'].play();
    let panel: AssetPlacement[] = [
        {
            family: ['body', 'settings', 'background'],
            type: AssetType.Image,
            position_x: 612 + 15,
            position_y: 4 - 14,
            collection: 'desktop',
            key: 'settings-bg'
        },
        {
            family: ['body', 'settings', 'back_button'],
            type: AssetType.Button,
            position_x: 841 + 15,
            position_y: 30 - 14,
            collection: 'desktop',
            key: 'leaderboard-close-btn-bg',
            callback: () => {hideSettings(gameRef)}
        },
        {
            family: ['body', 'settings', 'back_arrow'],
            type: AssetType.Button,
            position_x: 859 + 15,
            position_y: 48 - 14,
            collection: 'desktop',
            key: 'close-settings-arrow',
            callback: () => {hideSettings(gameRef)}
        },
        {
            family: ['body', 'settings', 'text'],
            type: AssetType.Text,
            position_x: 717 + 15,
            position_y: 42 - 14,
            key: 'SOUND FX',
            styleFamily: ['settings', 'label']
        }
    ];
    if (gameRef.sound.mute) {
        panel = panel.concat(panel_on(gameRef, stateRef));
    } else {
        panel = panel.concat(panel_off(gameRef, stateRef));
    }
    panel.map(a => placeAsset(gameRef, stateRef, a));
    gameRef.components.body.border.bringToTop();
}
