/**
 * Set of sounds available in the game.
 * @type {[string,string,string,string,string,string,string,string,string,string,string,string,string]}
 */
export const AUDIO_FILES = [
    'Beep',
    'Correct-Answer',
    'Countdown-Bleep',
    'Game-Over-Times-Up-Leader-Winner',
    'Game-Over-Times-Up-Not-Leader',
    'Go',
    'Incorrect-Answer',
    'Leaderboard-Show',
    'Music-Bed-Skin-In-the-Game',
    'Question-Loop',
    'Select',
    'Submit-or-Toggle',
    'Woosh'
];